import React, { useState, useEffect, useRef } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from 'react-redux';
import methodModel from "../../methods/methods";
import { planType } from "../../models/type.model";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import statusModel from "../../models/status.model";
import SelectDropdown from "../../components/common/SelectDropdown";
import requiredModel from "../../models/required.model";
import { Tooltip } from "antd";
import MultiSelectDropdown from "../../components/common/MultiSelectDropdown";

const AddEditPlan = () => {
    const [features, setFeatures] = useState([])
    const [currencys, setCurrencys] = useState([])

    const defaultvalue = () => {
        let keys = { ...planType }
        Object.keys(planType).map(itm => {
            keys[itm] = ''
        })
        keys.status = 'active'
        return keys
    }
    const { id, copy } = useParams()
    const [form, setform] = useState(planType);
    const [checkedItems, setCheckedItems] = useState([]);
    const [startIndex, setStartIndex] = useState(-1);
    const [enterIndex, setEnterIndex] = useState(-1);
    const [selectedItem, setSelectedItem] = useState('');
    const [pricing, setPricing] = useState([]);
    const history = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const user = useSelector((state) => state.user);
    const dragItem = useRef();
    const dragItems = useRef();
    const dragOverItem = useRef();
    const formValidation = [
        // { key: 'feature', required: true },
        { key: 'status', required: true },
        { key: 'recommended', required: true },
    ]
    const [selectedFeatureIds, setSelectedFeatureIds] = useState([]);
    const trailPeriodDays = [
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
        { id: 6, name: '6' },
        { id: 7, name: '7' },
        { id: 8, name: '8' },
        { id: 9, name: '9' },
        { id: 10, name: '10' },
        { id: 11, name: '11' },
        { id: 12, name: '12' },
        { id: 13, name: '13' },
        { id: 14, name: '14' },
        { id: 15, name: '15' },
        { id: 16, name: '16' },
        { id: 17, name: '17' },
        { id: 18, name: '18' },
        { id: 19, name: '19' },
        { id: 20, name: '20' },
        { id: 21, name: '21' },
        { id: 22, name: '22' },
        { id: 23, name: '23' },
        { id: 24, name: '24' },
        { id: 25, name: '25' },
        { id: 26, name: '26' },
        { id: 27, name: '27' },
        { id: 28, name: '28' },
        { id: 29, name: '29' },
        { id: 30, name: '30' },
    ]

    const selectfeatures = (value, key, index) => {
        let updatedCheckedItems;
        if (checkedItems.includes(value)) {
            updatedCheckedItems = checkedItems.filter(item => item !== value);
        } else {
            updatedCheckedItems = [...checkedItems, value];
        }
        setCheckedItems(updatedCheckedItems);

        let updatedFeatureIds;
        if (selectedFeatureIds.includes(value)) {
            updatedFeatureIds = selectedFeatureIds.filter(featureId => featureId !== value);
        } else {
            updatedFeatureIds = [...selectedFeatureIds, value];
        }
        setSelectedFeatureIds(updatedFeatureIds);

        let checked = features[key][index].checked;
        features[key][index].checked = checked ? false : true;
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid) return
        let method = 'post'
        let url = 'api/plan'
        let value = {
            ...form,
            planFeatures: SelectedFeaturesList
        }
        // return
        let fpricing = []

        const getStripeId = (itm) => {
            let ext = pricing.find(pitm => (pitm.interval_count == itm.interval_count && pitm.currency == itm.currency))
            return ext ? ext.stripe_price_id : ''
        }

        if (form.monthlyPrice) {
            let oarr = Object.keys(form.monthlyPrice)
            let val = form.monthlyPrice
            let interval_count = 1
            oarr.map(itm => {
                let payload = {
                    interval: "month",
                    interval_count: interval_count,
                    currency: itm,
                    unit_amount: val[itm],
                    stripe_price_id: getStripeId({ interval_count: interval_count, currency: itm })
                }
                fpricing.push(payload)
            })
        }

        if (form.threeMonthPrice) {
            let oarr = Object.keys(form.threeMonthPrice)
            let val = form.threeMonthPrice
            let interval_count = 3
            oarr.map(itm => {
                let payload = {
                    interval: "month",
                    interval_count: interval_count,
                    currency: itm,
                    unit_amount: val[itm],
                    stripe_price_id: getStripeId({ interval_count: interval_count, currency: itm })
                }
                fpricing.push(payload)
            })
        }

        if (form.sixMonthPrice) {
            let oarr = Object.keys(form.sixMonthPrice)
            let val = form.sixMonthPrice
            let interval_count = 6
            oarr.map(itm => {
                let payload = {
                    interval: "month",
                    interval_count: interval_count,
                    currency: itm,
                    unit_amount: val[itm],
                    stripe_price_id: getStripeId({ interval_count: interval_count, currency: itm })
                }
                fpricing.push(payload)
            })
        }

        if (form.yearlyPrice) {
            let oarr = Object.keys(form.yearlyPrice)
            let val = form.yearlyPrice
            let interval_count = 12
            oarr.map(itm => {
                let payload = {
                    interval: "month",
                    interval_count: interval_count,
                    currency: itm,
                    unit_amount: val[itm],
                    stripe_price_id: getStripeId({ interval_count: interval_count, currency: itm })
                }
                fpricing.push(payload)
            })
        }
        // return 
        if (id && copy == 'false') {
            method = 'put'
            url = 'api/plan/update'
            value.pricing = fpricing
            value.id = id
        } else {
            delete value.id
        }
        loader(true)
        ApiClient.allApi(url, {...value,user_id:value?.user_id||[]}, method).then(res => {
            if (res.success) {
                // ToastsStore.success(res.message)
                history("/plans")
            }
            loader(false)
        })
    }

    const getFeatures = () => {
        ApiClient.get('api/grouped/features', { page: 1, count: 100, status: 'active' }).then(res => {
            if (res.success) {
                let data = res.data
                // const checked = (id) => {
                //     let value = { checked: false, index: 1000 }
                //     if (f) {
                //         Object.keys(f).map(oitm => {
                //             f[oitm].map((itm, i) => {
                //                 if (itm.id == id) {
                //                     value = { checked: itm.checked || false, index: i }
                //                 }
                //             })
                //         })
                //     }
                //     return value
                // }
                // Object.keys(data).map(oitm => {
                //     data[oitm].map((itm, i) => {
                //         let ext = checked(itm.id)
                //         data[oitm][i].checked = ext.checked
                //         data[oitm][i].index = ext.order
                //     })
                // })
                // Object.keys(data).map(oitm => {
                //     data[oitm].sort(function (a, b) { return a.index - b.index })
                // })
                setFeatures(data)
            }
        })
    }

    const getCurrency = () => {
        ApiClient.get('api/currency/applied', { page: 1, count: 100, status: 'active' }).then(res => {
            if (res.success) {
                setCurrencys(res.data.map(itm => {
                    itm.isoCode = itm.isoCode.toLowerCase()
                    return itm
                }))
            }
        })
    }

    useEffect(() => {
        if (id) {
            loader(true)
            ApiClient.get('api/plan/detail', { id }).then(res => {
                if (res.success) {
                    let value = res.data
                    let payload = planType

                    Object.keys(payload).map(itm => {
                        payload[itm] = value[itm]
                    })

                    if (payload.category) {
                        payload.category = value.category.id
                    }
                    payload.id = value._id
                    if (copy == 'true') {
                        payload.name = `Copy of ${value.name}`
                    }
                    payload.stripe_product_id = value.stripe_product_id
                    payload.trial_period_days = value?.trial_period_days
                    setPricing(value.pricing)
                    setSelectedFeatureIds(value?.planFeatures?.map(itm => itm?._id || itm?.id) || []);
                    setSelectedFeaturesList(value?.planFeatures?.map((item) => ({ ...item, featureId: item?.featureId?._id, costPerItem: item?.costPerItem?.length ? item?.costPerItem[0] : item?.costPerItem, costPerMonth: item?.costPerMonth?.length ? item?.costPerMonth[0] : item?.costPerMonth })))
                    // setFeatures(value.features || []);
                    setform({
                        ...payload
                    })
                }
                loader(false)
            })
        } else {
            setform(defaultvalue())
        }
        // if (!id) {
        getFeatures()
        // }
        getCurrency()
    }, [id])

    const dragStart = (e, position, key = '') => {
        dragItem.current = position;
        setStartIndex(position)
        setSelectedItem(key)
    };

    const dragEnter = (e, position, key = '') => {
        dragOverItem.current = position;
        setEnterIndex(position)
        const copyListItems = [...features[key]];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItems.current = { ...dragItems.current, [key]: copyListItems }
    };

    const drop = (e, key = '') => {
        setStartIndex(-1)
        setEnterIndex(-1)
        setSelectedItem('')
        const copyListItems = [...features[key]];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        // dragItems.current={...dragItems.current,[key]:copyListItems}
        setFeatures({ ...features, [key]: copyListItems });
    };

    const requiredCheck = (key) => {
        let value = false
        currencys.map(itm => {
            if (form?.[key]?.[itm.isoCode]) value = true
        })
        return value
    }

    const showData = (key) => {
        let value = features[key]
        if (dragItems && dragItems.current?.[key]) {
            value = dragItems.current?.[key]
        }
        return value
    }

    const [SelectedFeaturesList, setSelectedFeaturesList] = useState([]);
    const HandleFeautreChanging = (name, value, featureId, currency = null) => {
        const ListFeatures = SelectedFeaturesList || [];
        let FindedIndex = ListFeatures?.findIndex((item) => item?.featureId == featureId)
        if (FindedIndex >= 0) {
            if (currency) {
                ListFeatures[FindedIndex] = { ...ListFeatures[FindedIndex], [name]: { ...ListFeatures[FindedIndex][name], [currency]: value } }
            } else {
                ListFeatures[FindedIndex] = { ...ListFeatures[FindedIndex], [name]: value }
            }

        } else {
            let payload = {
                featureId: featureId,
                costPerMonth: null,
                costPerItem: null,
                [name]: value
            }
            if (currency) {
                payload[name] = { [currency]: value }
            }
            ListFeatures.push(payload)
        }
        setSelectedFeaturesList([...ListFeatures])
    }

    const FeaturesValueReturn = (name, Feautreid, currency = null) => {
        let data = SelectedFeaturesList?.find(item => item?.featureId == Feautreid);
        if (currency) {
            if (data?.[name]?.[currency]) {
                return data[name]?.[currency]
            } else {
                return ""
            }
        } else {
            if (data?.[name]) {
                return data[name]
            } else {
                return ""
            }
        }

    }

    const CostPerItemShower = (name) => {
        let dataName = name?.toLowerCase();
        if (dataName == 'invoices' || dataName == 'contracts' || dataName == 'waviers') {
            return true
        } else {
            return false
        }
    }


    const [CustomerListing, setCustomerListing] = useState([]);

    const GetCustomerListing = () => {
        ApiClient.get(`api/user/list/dropdown?role=64b15102b14de6c28838f7d2&isDeleted=false`).then(res => {
            if (res.success) {
                setCustomerListing(res?.data)
            }
        })
    }
    useEffect(() => {
        GetCustomerListing()
    }, []
    )
    return <>
        <Layout>
            <form onSubmit={handleSubmit}>
                <div className="shadow-box w-full bg-white rounded-lg mt-6 border border-grey">

                    <div className='flex items-center mb-6 p-4'>
                        <Tooltip placement="top" title="Back">
                            <Link to="/plans" className="!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#F3F2F5] border  transition-all   mr-3"><i className='fa fa-angle-left text-lg'></i></Link>
                        </Tooltip>
                        <div>
                            <h3 className="text-2xl font-semibold text-[#111827]">
                                {id && copy == 'false' ? 'Edit' : 'Add'} Plan
                            </h3>
                            <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Plan</p>
                        </div>
                    </div>




                    <div className="grid grid-cols-12 gap-4 px-4">
                        <div className="col-span-12 md:col-span-6">
                            <label>Name<span className="star">*</span></label>
                            <input
                                type="text"
                                className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 border border-grey"
                                value={form.name}
                                onChange={e => setform({ ...form, name: e.target.value })}
                                required
                            />
                        </div>
                        {/* <div className="col-span-12 md:col-span-6">
                            <label>Price<span className="star">*</span></label>
                            <input
                                type="text"
                                className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 border border-grey"
                                value={form.price}
                                maxLength="10"
                                disabled={id ? true : false}
                                onChange={e => setform({ ...form, price: methodModel.isNumber(e) })}
                                required
                            />
                        </div> */}
                        <div className="col-span-12 md:col-span-6">
                            <label>Status<span className="star">*</span></label>
                            <div className="custom_dropdown">
                                <SelectDropdown
                                    isSingle={true}
                                    theme="search"
                                    id="statusDropdown"
                                    displayValue="name"
                                    placeholder="Select Status"
                                    intialValue={form.status}
                                    result={e => { setform({ ...form, status: e.value }) }}
                                    options={statusModel.list}
                                />
                                {submitted && !form.status ? <div className="text-danger">Status is Required</div> : <></>}
                            </div>
                        </div>
                        {/* <div className="col-span-12 md:col-span-6">
                            <label>Plan Type<span className="star">*</span></label>
                            <SelectDropdown
                                isSingle={true}
                                id="statusDropdown"
                                displayValue="name"
                                placeholder="Select Plan Type"
                                intialValue={form.interval}
                                result={e => { setform({ ...form, interval: e.value }) }}
                                options={planTypeModel.list}
                            />
                            {submitted && !form.interval ? <div className="text-danger">Plan Type is Required</div> : <></>}
                        </div> */}
                        <div className="col-span-12 md:col-span-6">
                            <label>Recommended<span className="star">*</span></label>
                            <div className="custom_dropdown">
                                <SelectDropdown
                                    isSingle={true}
                                    id="statusDropdown"
                                    displayValue="name"
                                    theme="search"
                                    placeholder="Select Recommendation"
                                    intialValue={form.recommended}
                                    result={e => { setform({ ...form, recommended: e.value }) }}
                                    options={requiredModel.list}
                                />
                                {submitted && !form.recommended ? <div className="text-danger">Recommended is Required</div> : <></>}
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label>Trail Period<span className="star">*</span></label>
                            <div className="custom_dropdown">
                                <SelectDropdown
                                    isSingle={true}
                                    id="statusDropdown"
                                    theme="search"
                                    displayValue="name"
                                    placeholder="Select Days"
                                    intialValue={form.trial_period_days}
                                    result={e => { setform({ ...form, trial_period_days: e.value }) }}
                                    options={trailPeriodDays}
                                />
                            </div>
                        </div>

                        <div className="col-span-12 md:col-span-6">
                            <label>Dazhboard Customer</label>
                            <div className="custom_dropdown">

                                <MultiSelectDropdown
                                    id="currency"
                                    displayValue="fullName"
                                    placeholder='All'
                                    intialValue={form?.user_id}
                                    result={e => setform({ ...form, user_id: e.value||[]})}
                                    options={CustomerListing}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-span-full mt-6">
                        <table className="w-full">
                            <thead className="border-b border-[#EAECF0]">
                                <tr>
                                    <th className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 w-[150px]">Duration</th>
                                    {currencys.map((itm, i) => {
                                        return <th key={i} className="text-uppercase cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 ">
                                            {itm.isoCode}
                                        </th>
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">1 Month</td>
                                    {currencys.map((item, i) => {
                                        return <td key={i} className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                                            <div className="col-spam-12 md:col-span-4 pl-3 mb-3">
                                                <input className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 border border-grey" name={item.isoCode} value={form.monthlyPrice ? form.monthlyPrice[item.isoCode] : ''} required={requiredCheck('monthlyPrice')} maxLength="10" onChange={e => { setform({ ...form, monthlyPrice: { ...form.monthlyPrice, [e.target.name]: methodModel.isNumber(e) } }) }} placeholder={`Price ${item.isoCode.toUpperCase()}`} />
                                            </div>
                                        </td>
                                    })}
                                </tr>
                                <tr>
                                    <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">3 Month</td>
                                    {currencys.map((item, i) => {
                                        return <td key={i} className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                                            <div className="col-spam-12 md:col-span-4 pl-3 mb-3">
                                                <input className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 border border-grey" name={item.isoCode} value={form.threeMonthPrice ? form.threeMonthPrice[item.isoCode] : ''} required={requiredCheck('threeMonthPrice')} maxLength="10" onChange={e => { setform({ ...form, threeMonthPrice: { ...form.threeMonthPrice, [e.target.name]: methodModel.isNumber(e) } }) }} placeholder={`Price ${item.isoCode.toUpperCase()}`} />
                                            </div>
                                        </td>
                                    })}
                                </tr>
                                <tr>
                                    <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">6 Month</td>
                                    {currencys.map((item, i) => {
                                        return <td key={i} className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                                            <div className="col-spam-12 md:col-span-4 pl-3 mb-3">
                                                <input className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 border border-grey" name={item.isoCode} value={form.sixMonthPrice ? form.sixMonthPrice[item.isoCode] : ''} required={requiredCheck('sixMonthPrice')} maxLength="10" onChange={e => { setform({ ...form, sixMonthPrice: { ...form.sixMonthPrice, [e.target.name]: methodModel.isNumber(e) } }) }} placeholder={`Price ${item.isoCode.toUpperCase()}`}></input>
                                            </div>
                                        </td>
                                    })}
                                </tr>
                                <tr>
                                    <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">12 Month</td>
                                    {currencys.map((item, i) => {
                                        return <td key={i} className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                                            <div className="col-spam-12 md:col-span-3 pl-3 mb-3">
                                                <input className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 border border-grey" name={item.isoCode} value={form.yearlyPrice ? form.yearlyPrice[item.isoCode] : ''} required={requiredCheck('yearlyPrice')} maxLength="10" onChange={e => { setform({ ...form, yearlyPrice: { ...form.yearlyPrice, [e.target.name]: methodModel.isNumber(e) } }) }} placeholder={`Price ${item.isoCode.toUpperCase()}`}></input>
                                            </div>
                                        </td>
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="col-span-12 md:col-span-12">
                        <hr className="borderHr" />
                        <div className="mt-3 mb-6 px-4">
                            <h5 className="text-2xl font-semibold text-[#111827]">Pricing </h5>
                        </div>
                        <table className="w-full mb-4">
                            <thead className="border-b border-[#EAECF0]">
                                <tr>
                                    <th className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 w-[150px]">Module</th>

                                    <th className="text-uppercase cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 ">
                                        Includes
                                    </th>
                                    <th className="text-uppercase cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 ">
                                        Add-ons
                                    </th>

                                    <th className="text-uppercase cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 ">
                                        Cost Per Item
                                    </th>


                                    < th className="text-uppercase cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 ">
                                        Cost Per Month
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(features).map((oitm, i) => {
                                    return <>
                                        {/* <tr>
                                        <td colspan="5" className="!text-typo !border-l-0  cursor-pointer !px-3.5 text-md font-normal !py-4 !border text-left border-[#EAECF0]"> <b>{oitm}</b></td>
                                    </tr> */}
                                        {features && showData(oitm)?.map((item, index) => (<tr>
                                            <td className="!text-typo  cursor-pointer !border-l-0 !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">{item?.name}</td>
                                            <td className="!text-typo  cursor-pointer !border-l-0 !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                                                <label class="form-check-label pointer">
                                                    <input class="form-check-input relative" onChange={e => {
                                                        HandleFeautreChanging('included', e.target.checked, item?._id);
                                                        currencys?.map((itm) => {
                                                            HandleFeautreChanging('costPerItem', '', item?._id, itm?.isoCode);
                                                            HandleFeautreChanging('costPerMonth', Number(0), item?._id, itm?.isoCode);
                                                        })
                                                        HandleFeautreChanging('addOn', false, item?._id);
                                                    }} checked={FeaturesValueReturn('included', item?._id)} type="checkbox" />

                                                </label>

                                            </td>

                                            <td className="!text-typo  cursor-pointer !border-l-0 !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                                                <label class="form-check-label pointer">
                                                    <input class="form-check-input relative" onChange={e => {
                                                        HandleFeautreChanging('addOn', e.target.checked, item?._id);
                                                        if (!e.target.checked) {
                                                            currencys?.map((itm) => {
                                                                HandleFeautreChanging('costPerItem', '', item?._id, itm?.isoCode);
                                                                HandleFeautreChanging('costPerMonth', Number(e.target.value), item?._id, itm?.isoCode);
                                                            })
                                                        }
                                                        HandleFeautreChanging('included', false, item?._id);
                                                    }} type="checkbox" checked={FeaturesValueReturn('addOn', item?._id)} />

                                                </label>

                                            </td>


                                            <td className="!text-typo  cursor-pointer !border-l-0 !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                                                {!FeaturesValueReturn('included', item?._id) && currencys.map((itm) => (
                                                    CostPerItemShower(item?.name) &&
                                                    <div className="">
                                                        <input placeholder={`Enter ${itm?.isoCode?.toUpperCase()}`}
                                                            required={SelectedFeaturesList?.find(itm => itm?.featureId == item?._id)?.addOn && !FeaturesValueReturn('costPerMonth', item?._id, itm?.isoCode)}
                                                            maxLength={10} onChange={e => HandleFeautreChanging('costPerItem', methodModel.isNumber(e), item?._id, itm?.isoCode)} disabled={SelectedFeaturesList?.find(itm => itm?.featureId == item?._id)?.included} value={FeaturesValueReturn('costPerItem', item?._id, itm?.isoCode)} className="relative shadow-box bg-white w-full rounded-lg mb-2 h-10 flex items-center gap-2 overflow-hidden px-2 border border-grey" />
                                                    </div>

                                                ))}
                                            </td>

                                            <td className="!text-typo  cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                                                {!FeaturesValueReturn('included', item?._id) && currencys.map((itm) => (
                                                    <div className="">
                                                        <input placeholder={`Enter ${itm?.isoCode?.toUpperCase()}`} maxLength={10} required={SelectedFeaturesList?.find(itm => itm?.featureId == item?._id)?.addOn && !FeaturesValueReturn('costPerItem', item?._id, itm?.isoCode)} onChange={e => HandleFeautreChanging('costPerMonth', methodModel.isNumber(e), item?._id, itm?.isoCode)} value={FeaturesValueReturn('costPerMonth', item?._id, itm?.isoCode)} disabled={SelectedFeaturesList?.find(itm => itm?.featureId == item?._id)?.included} className="relative shadow-box bg-white w-full rounded-lg mb-2 h-10 flex items-center gap-2 overflow-hidden px-2 border border-grey" />
                                                    </div>
                                                ))}
                                            </td>
                                        </tr >))}
                                    </>
                                })}



                            </tbody>
                        </table>
                    </div>
                    <div className="col-span-12 md:col-span-12">
                        <hr className="borderHr" />
                        <div className="mt-3 mb-6 px-4">
                            <h5 className="text-2xl font-semibold text-[#111827]">Feature Text </h5>
                        </div>
                        <div className="p-3">
                            <textarea
                                value={form?.description}
                                style={{ minHeight: '150px' }}
                                className="relative shadow-box bg-white w-full rounded-lg  flex items-center gap-2 overflow-hidden px-2 border border-grey"
                                onChange={e => setform(prev => ({ ...prev, description: e.target.value }))}
                            />
                        </div>
                    </div>
                    <div className="flex justify-end">

                        <button type="submit" className="!px-4 text-sm font-medium text-primary hover:text-white hover:no-underline h-10 flex items-center justify-center gap-2 bg-white rounded-extra_large shadow-btn hover:bg-primary border border-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">Save</button>
                    </div>
                </div>
            </form>
        </Layout >
    </>
}

export default AddEditPlan